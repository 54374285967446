<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex shrink>
        <v-card class="rounded-xl" elevation="12" width="350" style="border: #757575 1px solid !important;">
          <v-card-text>
            <!-- <v-layout class="mt-3" row justify-center>
              <v-flex shrink>
                <v-img alt="Prepaylink logo" width="180" class="shrink text-center" contain
                  src="@/assets/cutlogo.png" />
              </v-flex>
            </v-layout> -->
            <div class="text-center text-h4 black--text font-weight-bold">Login</div>
            <v-card-text>
              <v-row class="mt-5">
                <v-col cols="12">
                  <v-text-field v-model="username" outlined dense :hide-details="usernameErrors.length === 0"
                    :error-messages="usernameErrors" label="Username"></v-text-field>
                </v-col>


                <v-col cols="12">
                  <v-text-field outlined dense v-model="password" label="Password"
                    :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'" :type="showPassword ? 'text' : 'password'"
                    v-on:keyup.enter="submit" @click:append="showPassword = !showPassword"
                    v-on:keyup.enter.prevent="submit" :hide-details="passwordErrors.length === 0"
                    :error-messages="passwordErrors" @input="$v.password.$touch()">
                  </v-text-field>
                </v-col>



                <v-col cols="12">
                  <v-btn :loading="loading" block v-on:click.stop="submit" color="primary">Login</v-btn>
                </v-col>
                <v-col cols="12">
                  <v-btn block text>Forgot Password</v-btn>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider class="mt-4"></v-divider>

            <v-card-actions class="pa-0">
              <v-card flat width="100%" class="mt-2" color="transparent">

                <v-btn @click="createAccount" dark block text color="#00BFA5">Create Account</v-btn>

                <!-- <div class="text-center">
                  <span class="pointer" style="color: #7267E9;" @click="createAccount">
                    Create Account
                  </span>

                </div> -->
              </v-card>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import authService from "../service";

const { validationMixin } = require("vuelidate");
const { required, requiredIf } = require("vuelidate/lib/validators");

export default {
  name: "authentication-login",
  data() {
    return {
      loading: false,
      showPassword: false,
      // email: 'saqib@cellsmartpos.com',
      username: null,
      password: null,
      siteAddress: 'Pre Pay Activation',
      loginWithEmail: false
    };
  },
  components: {},
  async created() {
    localStorage.removeItem(`${process.env.VUE_APP_STORAGE_PREFIX}token`);
  },
  methods: {
    ...mapActions("global", ["setContact"]),
    submit() {
      this.$v.$touch();
      // if (this.email) 
      // this.email = this.email.toLowerCase();
      if (this.$v.$invalid) {
        this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: true,
            timer: 2000,
          })
          .fire({
            type: "error",
            title: "Invalid Input",
          });
      } else {
        this.loading = true;
        this.$Progress.start();
        let authObject = {
          password: this.password,
          siteAddress: this.siteAddress,
          code: '6372767966969926',
        }
        // if (this.loginWithEmail) authObject.email = this.email
        authObject.username = this.username
        return authService
          .authenticate(authObject)
          .then(async (response) => {
            console.log("responsse onlogin", response);
            this.$Progress.finish();
            // this.checkResponse(response);
            if (response && response.data && response.data.token) {
              await localStorage.setItem(
                `${process.env.VUE_APP_STORAGE_PREFIX}token`,
                response.data.token
              );
              await localStorage.setItem(
                `${process.env.VUE_APP_STORAGE_PREFIX}contact`,
                JSON.stringify(response.data.contact)
              );
              this.setContact(response.data.contact);

              const webToken = localStorage.getItem(
                `${process.env.VUE_APP_STORAGE_PREFIX}webActivationToken`
              );
              if (!webToken)
                this.$router.push({ path: "/webauthenticator" });
              else
                this.$router.push({ path: "/" });
            }
          })
          .catch((error) => {
            this.loading = false;
            this.$Progress.finish();
            console.log('error', error);
            this.$swal("Login", "Invalid user name or password", "warning");
          });
      }
    },
    createAccount() {
      this.$router.push({ name: 'create-account' });
    }
  },
  computed: {
    ...mapGetters("global", ["getContactNumber"]),
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      // if (!this.$v.email.email) errors.push("Email is not valid");
      if (!this.$v.email.required) errors.push("Email is required.");
      return errors;
    },
    usernameErrors() {
      const errors = [];
      if (!this.$v.username.$dirty) return errors;
      if (!this.$v.username.required) errors.push("Email is required.");
      return errors;
    },

    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      if (!this.$v.password.required) errors.push("Password is required.");
      return errors;
    },
  },
  mixins: [validationMixin],
  validations() {
    return {
      email: {
        // email,
        required: requiredIf(() => {
          if (this.loginWithEmail) return true;
        }),
      },
      password: {
        required,
      },
      username: {
        required: requiredIf(() => {
          if (!this.loginWithEmail) return true;
        }),
      }
    }
  },
};
</script>

<style scoped>
/* #login {
  background-image: url(https://picsum.photos/1200/?random&blur);
  background-size: cover;
} */

input[type="text"] {
  background: transparent;
}
</style>


